import React from "react"
import {Home, Settings, Circle} from "react-feather"

import {trans} from '@utils'

export const Navigation = [
    {
        id: 'stock',
        title: trans('stock.nav.stockManagement'),
        icon: <Home size={20}/>,
        children:[
            {
                id: 'centerId',
                title: trans('stock.nav.stockSettings'),
                icon: <Settings />,
                navLink: '/centers/list',
                children:[
                    {
                        id: 'category',
                        title: trans('stock.nav.categoryList'),
                        icon: <Circle size={20}/>,
                        navLink: '/categories/list',
                        action: 'call',
                        resource: 'STOCK_ADMIN_PERMISSION'
                    },
                    {
                        id: 'unitOfMeasurement',
                        title: trans('stock.nav.unitOfMeasurementList'),
                        icon: <Circle size={20}/>,
                        navLink: '/unitOfMeasurements/list',
                        action: 'call',
                        resource: 'STOCK_ADMIN_PERMISSION'
                    },
                    {
                        id: 'currencies',
                        title: trans('stock.nav.currencies'),
                        icon: <Circle size={20}/>,
                        navLink: '/currencies/list',
                        action: 'call',
                        resource: 'STOCK_ADMIN_PERMISSION'
                    }
                ]
            },
            {
                id: 'centerSS',
                title: trans('stock.nav.centerList'),
                icon: <Home size={20}/>,
                navLink: '/centers/list',
                action: 'call',
                resource: 'STOCK_ADMIN_PERMISSION'
            },
            {
                id: 'mycenter',
                title: trans('stock.nav.myCenter'),
                icon: <Home size={20}/>,
                navLink: '/my-center',
                action: 'call',
                resource: 'STOCK_ALL_PERMISSION'
            }
        ]
    }
]
