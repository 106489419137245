const PluggedInModules = {}

try {
    PluggedInModules['user'] = {path: require('@devModules/user'), enabled: true} // special user Module ()
} catch (e) {
    // console.error('no User Module', e)
}

try {
    PluggedInModules['stock'] = {path: require('@devModules/stock'), enabled: true} // special user Module ()
} catch (e) {
    // console.error('no User Module', e)
}

export default PluggedInModules
